export default [
  {
    path: '/adm/inicio',
    name: 'adm-inicio',
    component: () => import('@/views/app/Adm/Inicio.vue'),
    meta: { action: 'ecd' },
  },
  // ------------------------ Cliente ------------------------ \\
  {
    path: '/admin/cliente/adicionar',
    name: 'adm-cliente-adicionar',
    component: () => import('@/views/app/cliente/indexCliente.vue'),
    meta: { action: 'adm' },
  },
  {
    path: '/admin/cliente',
    name: 'adm-cliente',
    component: () => import('@/views/app/cliente/indexClientesLista.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/cliente/:id',
    name: 'adm-cliente-ver',
    component: () => import('@/views/app/cliente/indexCliente.vue'),
    meta: {
      action: 'ecd',
      pageTitle: 'Cliente',
      breadcrumb: [
        {
          text: 'clientes',
        },
        {
          text: 'Cadastro',
        },
      ],
    },
  },
  // ------------------------ Usuario ------------------------ \\
  {
    path: '/admin/usuario/adicionar',
    name: 'adm-usuario-adicionar',
    component: () => import('@/views/app/Usuario/indexUsuario.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/usuario',
    name: 'adm-usuario',
    component: () => import('@/views/app/Usuario/indexUsuarioLista.vue'),
    meta: { action: 'ecd' },
  },
  // ------------------------ Contratos ------------------------ \\

  {
    path: '/admin/contrato',
    name: 'adm-contrato',
    component: () => import('@/views/app/contrato/indexContratoLista.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/contrato/:id',
    name: 'adm-contrato-ver',
    component: () => import('@/views/app/contrato/indexContrato.vue'),
    meta: {
      action: 'ecd',
      pageTitle: 'Contratos',
      breadcrumb: [
        {
          text: 'Cliente',
        },
        {
          text: 'Contrato',
        },
      ],
    },
  },
  // ------------------------ Obras ------------------------ \\
  {
    path: '/admin/obra/adicionar',
    name: 'adm-obra-adicionar',
    component: () => import('@/views/app/obra/indexObra.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/obra',
    name: 'adm-obra',
    component: () => import('@/views/app/obra/indexObraLista.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/obra/permissoes/:id',
    name: 'adm-obra-permissoes',
    component: () => import('@/views/app/obra/Permissoes.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/obra/:id',
    name: 'adm-obra-ver',
    component: () => import('@/views/app/obra/indexObra.vue'),
    meta: {
      action: 'ecd',
      pageTitle: 'Obras',
      breadcrumb: [
        {
          text: 'Cliente',
        },
        {
          text: 'Contrato',
        },
        {
          text: 'Obra',
        },
      ],
    },
  },
  //* ******************** Mais Opções *********************
  {
    path: '/admin/obralista/opcoes/:id',
    name: 'admin-obralista-opcoes-id',
    component: () => import('@/views/app/obra/indexOpcoes.vue'),
    meta: { action: 'ecd' },
  },
  // ------------------------ Disciplinas ------------------------ \\
  {
    path: '/admin/disciplina/adicionar',
    name: 'adm-disciplina-adicionar',
    component: () => import('@/views/app/disciplina/indexDisciplina.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/disciplinas',
    name: 'adm-disciplinas',
    component: () => import('@/views/app/disciplina/indexDisciplinaLista.vue'),
    meta: { action: 'ecd' },
  },
  // ------------------------ Atividades ------------------------ \\
  {
    path: '/admin/atividade/adicionar',
    name: 'adm-atividade-adicionar',
    component: () => import('@/views/app/atividade/indexAtividade.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/atividades',
    name: 'adm-atividades',
    component: () => import('@/views/app/atividade/indexAtividadeLista.vue'),
    meta: { action: 'ecd' },
  },
  // ------------------------ Descrição ------------------------ \\
  {
    path: '/admin/descricao/adicionar',
    name: 'adm-descricao-adicionar',
    component: () => import('@/views/app/descricao/indexDescricao.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/descricao',
    name: 'adm-descricao',
    component: () => import('@/views/app/descricao/indexDescricaoLista.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/descricao/:id',
    name: 'adm-descricao-ver',
    component: () => import('@/views/app/descricao/indexDescricao.vue'),
    meta: {
      action: 'ecd',
      pageTitle: 'Descrições',
      breadcrumb: [
        {
          text: 'Cliente',
        },
        {
          text: 'Contrato',
        },
        {
          text: 'Obra',
        },
        {
          text: 'Descrição',
        },
      ],
    },
  },
  // ------------------------ Config ------------------------ \\

  {
    path: '/admin/disciplina/incluir',
    name: 'adm-disciplina-incluir',
    component: () => import('@/views/app/disciplina/indexIncluirDisciplina.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/disciplina',
    name: 'adm-disciplina',
    component: () => import('@/views/app/disciplina/indexListaDisciplina.vue'),
    meta: { action: 'ecd' },
  },

  {
    path: '/admin/atividade/incluir',
    name: 'adm-atividade-incluir',
    component: () => import('@/views/app/atividade/indexIncluirAtividade.vue'),
    meta: { action: 'ecd' },
  },
  {
    path: '/admin/atividade',
    name: 'adm-atividade',
    component: () => import('@/views/app/atividade/indexListaAtividade.vue'),
    meta: { action: 'ecd' },
  },
]
